import classes from '../../TableStyles.module.css';
import deleteBtn from '../../../assets/close.svg';
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../../Components/UI/Loading/Loading";

let url = `${process.env.REACT_APP_NODE_PORT}`;


function PopupData({role}) {

    const [fetchedPopupData,setFetchedPopupData] = useState([]);
    const [loading,setLoading] = useState(null);
    const getPopupData = async () => {
        setLoading(true)
        const res = await axios.get(`${url}/popup`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            // console.log(res.data.data);
            setFetchedPopupData(res.data.data);
            setLoading(false);
        }
    }

    useEffect(()=>{
        getPopupData()
    },[])


    async function onDeleteClickHandler(id){
        const delResult = await axios.delete(`${url}/popup/${id}`);
        if(delResult.data.status === 201){
            // console.log('data deleted');
            await getPopupData();
        }
    }


    return (
        <div className={classes.container}>
            {loading && <Loading/>}
            {!loading && (
                <table>
                    <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Logo Url</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Message Type</th>
                        {role==='main' && <th>Delete</th>}
                    </tr>
                    {
                        fetchedPopupData.map((row)=>{
                            return(
                                <tr key={row.id}>
                                    <td>{row.startdate}</td>
                                    <td>{row.enddate}</td>
                                    <td>{row.logourl}</td>
                                    <td>{row.title}</td>
                                    <td style={{textAlign:'center',justifyItems:'center',overflow:"scroll",width:'fit-content'}}>{row.message}</td>
                                    <td>{row.messagetype}</td>
                                    {role==='main' && <td style={{textAlign: 'center'}}><img src={deleteBtn} alt={'Delete'}
                                                                            height={'30px'} width={'30px'}
                                                                            onClick={() => onDeleteClickHandler(row.id)}/>
                                    </td>}
                                </tr>
                            )
                        })
                    }
                    </thead>
                </table>
            )}
        </div>
    );
}

export default PopupData;
