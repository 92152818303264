import './Carousel.css';
import Slider from "../../UI/ImageSlider/Slider";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../UI/Loading/Loading";

// let url = "172.23.0.1"
let url = `${process.env.REACT_APP_NODE_PORT}`;
function Carousel() {
    const [isLoading,setIsLoading] = useState(false);
    const [fetchedCarouselImagesFromDb,setFetchedCarouselImagesFromDb] = useState([])
    const getAwardImgData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/main-carousel`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            console.log(res.data.data)
            setFetchedCarouselImagesFromDb(res.data.data.map(image => ({
                image:image.imgurl
            })))
            // console.log("Carousel Images => " +fetchedCarouselImagesFromDb)
            setIsLoading(false)
            // console.log("Carousel Images => " +fetchedCarouselImagesFromDb)
        }
        // console.log("Carousel Images => " +fetchedCarouselImagesFromDb)
    }

    useEffect(() =>
    {
        getAwardImgData()
    },[])
    return ( isLoading ? <Loading /> : <Slider slides={fetchedCarouselImagesFromDb} />);
}

export default Carousel;
