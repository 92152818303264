import React, {useEffect, useState} from 'react';
import Carousel from "./Carousel/Carousel";
import TeamCarousel from "./TeamCarousel/TeamCarousel";
import NLComponent from "./NLComponent/NLComponent";
import PRComponent from "./PRComponent/PRComponent";
import VideoYT from "./VideoYT/VideoYT";
import ClientCarouselMain from "./ClientCarousel/ClientCarouselMain";
import Modal from "../UI/Modal/Modal";
import axios from "axios";
import moment from "moment";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function Home({carouselImages}) {

    const [showModal,setShowModal]  = useState(false);
    const [closeModal,setCloseModal] = useState(false);
    const [fetchedPopupData,setFetchedPopupData] = useState([]);
    const [loading,setLoading] = useState(null);
    const getPopupData = async () => {
        setLoading(true)
        const res = await axios.get(`${url}/popup`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            // console.log(res.data.data[0])

            setFetchedPopupData(res.data.data.map((dataReceived) => ({
                startDate:dataReceived.startdate,
                endDate:dataReceived.enddate,
                title:dataReceived.title,
                message:dataReceived.message,
                logoUrl:dataReceived.logourl,
                messageType:dataReceived.messagetype
            })))
            let receivedStartDate = moment(new Date(res.data.data[0].startdate)).format("YYYY-MM-DD")
            let receivedStartTime = moment(new Date(res.data.data[0].startdate)).format("hh:mm:ss")
            let receivedEndDate = moment(new Date(res.data.data[0].enddate)).format("YYYY-MM-DD")
            let receivedEndTime = moment(new Date(res.data.data[0].enddate)).format("hh:mm:ss")
            let todayDate = moment().format("YYYY-MM-DD")
            let todayTime = moment().format("hh:mm:ss")

            // console.log("SD"+receivedStartDate)
            // console.log("ST"+receivedStartTime)
            // console.log("ED"+receivedEndDate)
            // console.log("ET"+receivedEndTime)
            // console.log("TD"+todayDate)
            // console.log("TT"+todayTime)


            if(receivedStartDate >= todayDate && todayDate <= receivedEndDate){
                // console.log(receivedStartDate >= todayDate && todayDate <= receivedEndDate)
                if(todayTime >= receivedStartTime && todayTime <= receivedEndTime){
                    // console.log(todayTime >= receivedStartTime && todayTime <= receivedEndTime)
                    setShowModal(true);
                }else{
                    setShowModal(false);
                }
            }else{
                setShowModal(false);
            }
            setLoading(false);
        }
    }

    useEffect(()=>{
        getPopupData()
    },[]);


    const modalRemover = () => {
        setCloseModal(true);
    };
    return (
        <>
            {!loading && showModal && !closeModal && <Modal onConfirm={modalRemover} title={fetchedPopupData[0].title} message={fetchedPopupData[0].message} messageType={fetchedPopupData[0].messageType} logourl={fetchedPopupData[0].logoUrl}/>}
            <Carousel />
            <NLComponent />
            <PRComponent/>
            <ClientCarouselMain/>
            <VideoYT/>
            <TeamCarousel/>

        </>
    );
}

export default Home;
