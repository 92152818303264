import React, {useEffect, useState} from 'react';
import axios from "axios";
import TeamMemberCard from "../UI/TeamMemberCard/TeamMemberCard";
import Loading from "../UI/Loading/Loading";

// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;
function TeamMembers(props) {
    const [isLoading,setIsLoading] = useState(false);
    const [fetchedTeamMembersFromDb,setFetchedTeamMembersFromDb] = useState([]);
    const getMembersData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/team-data`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            setFetchedTeamMembersFromDb(res.data.data);
            // console.log(fetchedTeamMembersFromDb)
            setIsLoading(false)
        }
    }

    useEffect(() =>
    {
        getMembersData()
    },[])
    return (
        <div>
            {
                isLoading && <Loading />
            }
            { !isLoading &&
                fetchedTeamMembersFromDb.map((member)=>{
                    return(
                        <TeamMemberCard id={member.id} img={member.imgurl} name={member.name} position={member.position} description={member.desc}/>
                    )
                })
            }
        </div>
    );
}

export default TeamMembers;
