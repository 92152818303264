import React, {useState} from 'react';
import classes from './Images.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleChevronLeft, faCircleChevronRight, faCircleXmark} from "@fortawesome/free-solid-svg-icons";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function ImagesDisplay({data,path}) {
    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber( data.length -1 )
            : setSlideNumber( slideNumber - 1 )
    }

    // Next Image
    const nextSlide = () => {
        slideNumber + 1 === data.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }
    return (
        <div>
            {openModal &&
                <div className={classes.sliderWrap}>
                    <FontAwesomeIcon icon={faCircleXmark} className={classes.btnClose} onClick={handleCloseModal}  />
                    <FontAwesomeIcon icon={faCircleChevronLeft} className={classes.btnPrev} onClick={prevSlide} />
                    <FontAwesomeIcon icon={faCircleChevronRight} className={classes.btnNext} onClick={nextSlide} />
                    <div className={classes.fullScreenImage}>
                        <img src={`${url}/${path}/${data[slideNumber].imageurl}`} loading={'lazy'}/>
                    </div>
                </div>
            }
            <div className={classes.galleryWrap}>
                {
                    data && data.map((slide, index) => {
                        return(
                            <div
                                className={classes.single}
                                key={index}
                                onClick={ () => handleOpenModal(index) }
                            >
                                <img src={`${url}/${path}/${slide.imageurl}`} loading={'lazy'}/>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    );
}

export default ImagesDisplay;
