import classes from '../../TableStyles.module.css';
import deleteBtn from '../../../assets/close.svg';
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../../Components/UI/Loading/Loading";


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;

function PRData({role}) {

    const [fetchedPRData,setFetchedPRData] = useState([]);
    const [loading,setLoading] = useState(null);
    const getPRData = async () => {
        setLoading(true)
        const res = await axios.get(`${url}/press-releases`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            // console.log(res.data.data)
            setFetchedPRData(res.data.data)
            setLoading(false)
        }
    }

    useEffect(()=>{
        getPRData()
    },[])


    async function onDeleteClickHandler(id){
        const delResult = await axios.delete(`${url}/press-releases/${id}`);
        if(delResult.data.status === 201){
            // console.log('data deleted');
            await getPRData();
        }
    }


    return (
        <div className={classes.container}>
            {loading && <Loading/>}
            {!loading && (
                <table>
                    <thead>
                    <tr>
                        <th>Press-Release Logo</th>
                        <th>Client Name in Press-Release</th>
                        <th>Press-Release Heading</th>
                        <th>Press-Release Upload Date</th>
                        {role==='main' && <th>Delete</th>}
                    </tr>
                    {
                        fetchedPRData.map((row)=>{
                            return(
                                <tr key={row.id}>
                                    <td><img src={`${url}/clientsImg/${row.logourl}`} alt={'No logo Found'} height={'100px'} width={'100px'} loading={'lazy'}/></td>
                                    <td>{row.clientname}</td>
                                    <td>{row.heading}</td>
                                    <td>{row.date}</td>
                                    {role==='main' && <td style={{textAlign: 'center'}}><img src={deleteBtn} alt={'Delete'}
                                                                            height={'30px'} width={'30px'}
                                                                            onClick={() => onDeleteClickHandler(row.id)}/>
                                    </td>}
                                </tr>
                            )
                        })
                    }
                    </thead>
                </table>
            )}
        </div>
    );
}

export default PRData;
