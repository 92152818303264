import classes from './ServicesPage.module.css';
import {motion} from 'framer-motion'
import ServiceTile from "../UI/ServiceTile/ServiceTile";
function ServicesPage() {
    return (
        <>
            <div className={classes.aboutUsContainer}>
                <div className={classes.heading}>About Us</div>
                <div className={classes.aboutUs}>
                    <p>We embarked on a journey in the year 1992</p>
                    <p>With a clear focus on promoting Travel & Tourism.</p>
                    <p>Pioneering in the field of Representations, Travel Marketing, Media Communication, Trade Networking and Sales.</p>
                    <p>Since our inception, we have created various success stories.</p>
                    <p>With a proven TRACk record over the years, we have been instrumental in establishing destinations, hospitality brands, attractions and various travel entities as integral part of Indian Travel story.</p>
                </div>
            </div>
            <div className={classes.aboutUsContainer}>
                <div className={classes.heading}>Key Drivers</div>
                <div className={classes.aboutUs}>
                    <p>Driven by Passion, Determination & Enthusiasm</p>
                    <p>Fueled by Innovation, Market Intelligence & In-depth Insight</p>
                    <p>Inspired by Challenges, Barriers & Market Dynamics</p>
                    <p>Steered by Tailored Options, Customized Implementation & Optimal Outcome</p>
                </div>
            </div>
            <div className={classes.aboutUsContainer}>
                <div className={classes.heading}>Our USP</div>
                <div className={classes.aboutUs}>
                    <p>Edge over competition</p>
                    <p>Value driven solutions</p>
                    <p>Staying ahead of deadlines</p>
                    <p>Measurable & ROI driven Results</p>
                    <p>Client satisfaction is fundamental</p>
                </div>
            </div>
            <div className={classes.tagline}>
                <p>Your TRUSTED arm That’s ACCOUNTABLE</p>
            </div>

            <div className={classes.tagline}>
                <p>We Have Created PAN India Service Network</p>
            </div>

            <div className={classes.caterToContainer}>
                <div className={classes.caterBox1}>
                    <div className={classes.heading}>We Cater To</div>
                </div>
                <div className={classes.caterBox2}>
                    <div className={classes.caterToImg}></div>
                    <div className={classes.caterToText}>
                        <motion.p
                            style={{color:'black'}}
                            whileHover={{ scale: 1.3, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >Destinations</motion.p>
                        <motion.p
                            style={{color:'black'}}
                            whileHover={{ scale: 1.3, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >Regions & Cities</motion.p>
                        <motion.p
                            style={{color:'black'}}
                            whileHover={{ scale: 1.3, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >Cruises</motion.p>
                        <motion.p
                            style={{color:'black'}}
                            whileHover={{ scale: 1.3, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >Airlines</motion.p>
                        <motion.p
                            style={{color:'black'}}
                            whileHover={{ scale: 1.3, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >Hospitality</motion.p>
                        <motion.p
                            style={{color:'black'}}
                            whileHover={{ scale: 1.3, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                            transition={{ type: 'spring', stiffness: 300 }}
                        >Attractions</motion.p>
                    </div>
                </div>
            </div>


            <div className={classes.serviceHeading}>
                <p>Services We Provide</p>
            </div>
            {/*<div className={classes.servicesContainer}>*/}
            {/*    {*/}
            {/*        services.map( (service)=>{*/}
            {/*            return(*/}
            {/*                <div className={classes.serviceCard} key={service.id}>*/}
            {/*                    <ServiceTile serviceName={service.serviceName} />*/}
            {/*                </div>*/}
            {/*            )*/}
            {/*        } )*/}
            {/*    }*/}
            {/*</div>*/}
            <ServiceTile/>
        </>
    );
}

export default ServicesPage;
