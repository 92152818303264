import {Outlet} from "react-router-dom";
import Navbar from "./Components/OptionalNav/Nav/Navbar";
import Footer from "./Components/Footer/Footer";
import OptNav from "./Components/OptionalNav/OptNav";
import NavRes from "./Components/OptionalNav/NavRes/NavRes";

function Root(){
    return <>
        {/*<Navbar/>*/}
        <OptNav/>
        {/*<NavRes/>*/}
        <main>
            <Outlet/>
        </main>
        <Footer/>
    </>
}

export default Root
