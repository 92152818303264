import React from 'react';
import classes from './ContactUs.module.css';


function ContactUs() {
    return (
        <div className={classes.outerBox}>
            <div className={classes.innerBox}>
                <iframe
                    src={"https://docs.google.com/forms/d/e/1FAIpQLSfy5rKQsl-DGY5PDD64xFzMo4MwKQ-VCeY05uc1MG-Q3-vaqQ/viewform?embedded=true"}
                    /*remove emedded=true to remove bg image*/
                    width="100%" height="100%" >
                </iframe>
            </div>
        </div>
    );
}

export default ContactUs;
