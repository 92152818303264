import React from 'react';
import classes from './Footer.module.css'
import tracLogo from '../../assets/TRACLOGO.jpg'
import CopyrightIcon from '@mui/icons-material/Copyright';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
    return (
        <div className={classes.footerContainerOuter}>
            <div className={classes.middleContainer}>
                <div className={classes.footerContainerInner}>
                    <div className={classes.footerRight}>
                        <div className={classes.logoContainer}>
                            {/*<img src={tracLogo} alt={'X'} loading={'lazy'} height={'100px'} width={'200px'}/>*/}
                            <img src={tracLogo} alt={'X'} loading={'lazy'}  height={"70px"} width={"150px"}/>
                        </div>
                        <div className={classes.briefContainer}>
                            <p>WE MAKE CHALLENGES LOOK SIMPLE !</p>
                        </div>
                    </div>
                    <div className={classes.footerLeft}>
                        <div className={classes.contactUsContainer}>
                            <ul>
                                <li className={classes.listItems}><MarkEmailReadOutlinedIcon/>kg@tracrep.com</li>
                                <li className={classes.listItems}><PhoneOutlinedIcon/>+91 11 23352550</li>
                                <li className={classes.listItems}>
                                    <p><LocationOnOutlinedIcon/>TRAC Representations (I) Pvt. Ltd.
                                    A-61, 6th Floor, Himalaya House
                                    23, Kasturba Gandhi Marg, New Delhi – 110001, India</p>
                                </li>
                                <li>
                                    <div className={classes.socialIcons}>
                                        <a href={'https://www.instagram.com/tracrepindia/'} target={'_blank'}><InstagramIcon sx={{ "&:hover": { color: "red" } }}/></a>
                                        <a href={'https://twitter.com/tracrep'} target={'_blank'}><TwitterIcon sx={{ "&:hover": { color: "red" } }}/></a>
                                        <a href={'https://www.facebook.com/TRAC-Representations-India-Pvt-Ltd-313885807923'} target={'_blank'}><FacebookIcon sx={{ "&:hover": { color: "red" } }}/></a>
                                        <a href={'https://www.linkedin.com/in/trac-representations-i-pvt-ltd-90055090/'} target={'_blank'}><LinkedInIcon sx={{ "&:hover": { color: "red" } }}/></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={classes.footerCopyright}>
                    <hr /> <p style={{color:'black'}}> <CopyrightIcon/>2023</p> <hr />
                </div>
            </div>

        </div>
    );
}

export default Footer;
