import React, {useEffect, useState} from 'react';
import axios from "axios";
import ClientCarousel from "../../UI/HorizontalCarouselMarquee - DEP/ClientCarousel";
import Loading from "../../UI/Loading/Loading";
import MarQ from "../../UI/Marquee/MarQ";


// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;
function AwardImageMain() {
    const [isLoading,setIsLoading] = useState(false);



    const [fetchedAwardImagesFromDb,setFetchedAwardImagesFromDb] = useState([])
    const [data,setData] = useState([])
    const getAwardImgData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/award-img`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            setFetchedAwardImagesFromDb(res.data.data.map((image) => ({
                id:image.id,
                image:image.imageurl
            })))
            console.log(fetchedAwardImagesFromDb)
            setIsLoading(false)
        }
    }

    useEffect(() =>
    {
        getAwardImgData()
    },[])
    return ( isLoading ? <Loading /> : <MarQ width={500} height={700} speed={200} images={fetchedAwardImagesFromDb} staticPath={"awardImg"} pause={false}/> );

}

export default AwardImageMain;
