import classes from './NewsletterPage.module.css'
import axios from "axios";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useEffect, useState} from "react";
import PdfCard from "../UI/PdfCard/PdfCard";
import Loading from "../UI/Loading/Loading";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function NewsletterPage(props) {

    const [fetchedData,setFetchedData] = useState([]);
    const [loading,setLoading] = useState(null)
    const getNewsletterData = async () => {
            setLoading(true);
            const res = await axios.get(`${url}/newsletters`,{
                headers:{
                    'Content-Type':'application/json'
                }
            });
            if(res.data.status === 201){
                // console.log('data get');
                // console.log(res.data.data)
                setFetchedData(res.data.data)
                setLoading(false)
            }
        }

        useEffect(()=>{
            getNewsletterData()
        },[])
    return (
        <div className={classes.pdfContainer}>
            <div className={classes.nlHeadingContainer}>
                <p>Newsletter Updates</p>
            </div>
            {loading && <Loading /> }
            {!loading && (
                <>
                    {
                        fetchedData.length === 0
                        ?
                            <p className={classes.noData}>No New Data To Publish</p>
                        :
                            <div
                                className={classes.letterContainer}
                            >
                        {
                            fetchedData.map((pdf,id) => {
                            return(
                                    <div className={classes.card}>
                                        <PdfCard
                                            testLogo={pdf.logourl}
                                            cname={pdf.clientname}
                                            heading={pdf.heading}
                                            brief={pdf.brief}
                                            pdfURL={pdf.pdfurl}
                                            staticUrlLogo={'clientsImg'}
                                            staticUrlPDF={'newsletterData'}
                                        />
                                    </div>
                                 )
                            })
                        }
                        </div>
                    }
                </>
            )}
        </div>
    );
}

export default NewsletterPage;
