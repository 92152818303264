import React, {useEffect, useState} from 'react';
import axios from "axios";
import classes from "../../TableStyles.module.css";
import Loading from "../../../Components/UI/Loading/Loading";
import deleteBtn from "../../../assets/close.svg";

// let url = "http://localhost:8080"
let url = `${process.env.REACT_APP_NODE_PORT}`;
function AwardWithTextDescData({role}) {

    const [fetchedAwardsWithTextDescData,setFetchedAwardsWithTextDescData] = useState([]);
    const [loading,setLoading] = useState(null);
    const getAwardsWithTextDescData = async () => {
        setLoading(true)
        const res = await axios.get(`${url}/award-img-text`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            // console.log(res.data.data)
            setFetchedAwardsWithTextDescData(res.data.data)
            setLoading(false)
        }
    }

    useEffect(()=>{
        getAwardsWithTextDescData()
    },[])


    async function onDeleteClickHandler(id){
        const delResult = await axios.delete(`${url}/award-img-text/${id}`);
        if(delResult.data.status === 201){
            // console.log('data deleted');
            await getAwardsWithTextDescData();
        }
    }


    return (
        <div className={classes.container}>
            {loading && <Loading/>}
            {!loading && (
                <table>
                    <thead>
                    <tr>
                        <th>Award Image</th>
                        <th>Award Desc</th>
                        <th>ByLine</th>
                        {role==='main' && <th>Delete</th>}
                    </tr>
                    {
                        fetchedAwardsWithTextDescData.map((row)=>{
                            return(
                                <tr key={row.id}>
                                    <td><img src={`${url}/awardImgText/${row.imageurl}`} alt={'No logo Found'} height={'100px'} width={'200px'} loading={'lazy'}/></td>
                                    <td>{row.text}</td>
                                    <td>{row.byline}</td>
                                    {role==='main' && <td style={{textAlign: 'center'}}><img src={deleteBtn} alt={'Delete'}
                                                                            height={'30px'} width={'30px'}
                                                                            onClick={() => onDeleteClickHandler(row.id)}/>
                                    </td>}
                                </tr>
                            )
                        })
                    }
                    </thead>
                </table>
            )}
        </div>
    );
}

export default AwardWithTextDescData;
