import React, {useEffect, useState} from 'react';
import axios from "axios";
import Loading from "../../UI/Loading/Loading";
import MarQ from "../../UI/Marquee/MarQ";



// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;

function ClientCarouselMain(props) {
    const [isLoading,setIsLoading] = useState(false);



    const [fetchedClientsImagesFromDb,setFetchedClientsImagesFromDb] = useState([])
    const [data,setData] = useState([])
    const getClientData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/our-clients`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            setFetchedClientsImagesFromDb(res.data.data.map((image) => ({
               id:image.id,
               image:image.imageurl
            })))
            // console.log(fetchedClientsImagesFromDb)
            setIsLoading(false)
        }
    }

    useEffect(() =>
    {
        getClientData()
    },[])
    return (
        <>
            {/*{isLoading ? <Loading /> : <ClientCarousel images={fetchedClientsImagesFromDb} staticPath={"clientsImg"} title={"Affiliations & proudly serviced"} />}*/}
            {isLoading ? <Loading /> : <MarQ speed={150} height={300} width={200} images={fetchedClientsImagesFromDb} pause={false} staticPath={"clientsImg"} title={"Affiliations & proudly serviced"} />}
        </>
    );
}

export default ClientCarouselMain;
