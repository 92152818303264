import React, {useEffect, useState} from 'react';
import ReactPlayer from "react-player";
import axios from "axios";
import Loading from "../../UI/Loading/Loading";
import classes from './VideoYT.module.css'
import {useAnimation, motion} from "framer-motion";
import {useInView} from "react-intersection-observer";

// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;
function VideoYT() {

    const [ref,inView] = useInView({
        threshold:0.5  //80% of parent div must be there in view for animation to played
    });

    const videoContainerAnimation = useAnimation();

    const [fetchedYTLink,setFetchedYTLink] = useState([]);
    const [loading,setLoading] = useState(null);
    const [focusedVideo,setFocusedVideo] = useState('');


    const getYTData = async () => {
        setLoading(true);
        try{
          const res = await axios.get(`${url}/yt-data`,{
              headers:{
                  'Content-Type':'application/json'
              }
          });
          if(res.data.status === 201){
              setFetchedYTLink(res.data.data)
              // console.log('data get');
              // console.log(res.data.data[0])
              setFocusedVideo(res.data.data[0].yturl);
              setLoading(false)

          }else{
              console.log("error in fetch")
          }
      }catch (e) {
            console.log('Error in Fetch');
        }
    }

    useEffect(()=>{
        if(inView){
            videoContainerAnimation.start({
                y:0,
                transition:{
                    ease:'easeInOut',
                    type:'tween',
                    duration:1,
                    // delay:3,
                    // bounce:0.1
                }
            })
        }
    },[inView]);

    useEffect(()=>{
        getYTData()
    },[])



    function changeVideoHandler(id) {
        let updatedUrl = fetchedYTLink.filter((item) => item.id === id);
        setFocusedVideo(updatedUrl[0].yturl)
    }

    return (
        <div ref={ref}>
            <motion.div
                animate={videoContainerAnimation}
                className={classes.videoSectionContainer}
            >

                {loading && <Loading/>}
                {!loading && fetchedYTLink.length !==0 &&
                <div className={classes.sectionContainer}>
                    {/*<p className={classes.titleContainer}>{fetchedYTLink[0].title}</p>*/}
                    <div className={classes.titleContainer}>
                        <ul style={{listStyle:'none'}}>
                            {
                                fetchedYTLink.map((data) =>{
                                    return(
                                        <motion.li
                                            key={data.id}
                                            onClick={(id)=>changeVideoHandler(data.id)}
                                            style={{color:'black'}}
                                            whileHover={{ scale: 1.1, originX: 0, color: '#085397', textShadow: '3px 11px 15px rgba(151, 133, 108, 1)' }}
                                            transition={{ type: 'spring', stiffness: 300 }}
                                        >
                                            {data.title}
                                        </motion.li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className={classes.videoPlayerContainer}>
                        <ReactPlayer
                            className={classes['react-player']}
                            // url={fetchedYTLink[0].yturl}
                            url={focusedVideo}
                            width='100%'
                            height='100%'
                            loop
                            pip
                            playing={false}
                            controls
                            playsinline
                        />
                    </div>
                </div>
                }
            </motion.div>
        </div>
    );
}

export default VideoYT;
