import React,{useState,useEffect} from 'react';
import {FaTimes, FaBars} from 'react-icons/fa'
import { IconContext } from "react-icons/lib";
import styled from "styled-components";
import logo from "../../../assets/TRACLOGO.jpg"
import "./NavRes.css"
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

function NavRes() {
    const [click, setClick] = useState(false);


    const handleClick = () => {
        setClick(!click);
    };

    return (
        <>
            <IconContext.Provider value={{ color: "#085397" }}>
                <NavB>
                    <NavbarContainer>
                        <MobileIcon onClick={handleClick}>
                            <div style={{height:'100%',width:'20%'}}>
                                {   click
                                    ?
                                    <MoreVertIcon size="small" sx={{ fontSize: 40 , color:'#085397', "&:hover": { color: "#085397" } }} />
                                    :
                                    <MoreVertIcon size="small" sx={{ fontSize: 40 , color:'#085397', "&:hover": { color: "#085397" } }} />
                                }
                            </div>
                        </MobileIcon>
                        <NavMenu onClick={handleClick} click={click}>
                            <NavItem>
                                <NavLinks to="/">
                                    <a href='/'>
                                        <span> Home</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/newsletter-updates">
                                    <a href='/newsletter-updates'>
                                        <span> Newsletters</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/press-release-updates">
                                    <a href='/press-release-updates'>
                                        <span> Press Releases</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/awards-achievements">
                                    <a href='/awards-achievements'>
                                        <span> Achievements & Awards</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/gallery">
                                    <a href='/gallery'>
                                        <span> Gallery</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/services">
                                    <a href='/services'>
                                        <span>About Us & Services</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/our-team">
                                    <a href='/our-team'>
                                        <span>Core Team</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="/contact-us">
                                    <a href='/contact-us'>
                                        <span>Contact Us</span>
                                    </a>
                                </NavLinks>
                            </NavItem>
                        </NavMenu>
                    </NavbarContainer>
                </NavB>
            </IconContext.Provider>
        </>
    );
}



const NavB = styled.nav`
  //font-family: 'Montserrat Alternates', sans-serif;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: sticky;
  top: 0;
  z-index: 999;
`;

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 10vh;
  z-index: 1;
  width: 100vw;
  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;



const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    transform: translate(-200%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    transition: all 1s ease-in-out;
  }
  @media screen and (max-width: 960px){
    right: -5%;
    top: -10vh;
  }
  @media screen and (max-width: 650px){
    right: -10%;
    top: -10vh;
  }@media screen and (max-width: 380px){
    top: -10vh;
    right: -18%;
  }
  //@media screen and (max-width: 300px){
  //  top: -10vh;
  //  right: -18%;
  //}
  @media screen and (max-width: 260px){
    top: -10vh;
    right: -30%;
  }
  @media screen and (max-width: 225px){
    top: -10vh;
    right: -40%;
  }
  @media screen and (max-width: 200px){
    top: -10vh;
    right: -50%;
  }
  //@media screen and (max-width: 960px) and (min-width: 425px){
  //  left: 100%;
  //  top: -10vh;
  //}
  //@media only screen and (max-width: 424px) and (min-width: 324px){
  //  left: 95%;
  //  top:-12vh
  //}@media only screen and (max-width: 325px){
  //  left: 95%;
  //  top:-12vh
  //}
`;

const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  width: 100vw;
  justify-content: space-evenly;
  height: 100%;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    justify-content: flex-start;
    top: 0%;
    left: ${({ click }) => (click ? 0 : "-200%")};
    opacity: 1;
    transition: all 1s ease-in-out;
    background-color: white;
  }
`;

const NavItem = styled.li`
  height: 80px;
  border-bottom: 2px solid transparent;
  color: black;
  
  @media screen and (max-width: 960px) {
    width: 100%;

    &:hover {
      border: none;
    }
  }
`;

const NavLinks = styled.div`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: 'Montserrat Alternates', sans-serif;
  height: 100%;
  a {
    text-decoration: none;
    color: black;

    cursor: pointer;

    span {
      font-size: 20px;
      position: relative;

      &:after {
        content: "";
        height: 2px;
        background: black;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -6px;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: left center;
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
      }

      @media only screen and (max-width: 1050px) and (min-width: 790px){
        font-size: 15px;
      }
      @media only screen and (max-width: 325px){
       font-size: 13px;
      }
    }

    &:hover {
      span:after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 1030px){
    font-size: 12px;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: flex;

    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;



export default NavRes;
