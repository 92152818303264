import React, {useCallback, useEffect, useRef, useState} from 'react';
import classes from "./TeamCarousel.module.css"
import axios from "axios";
import HomePageTeamCard from "../../UI/HomePageTeamCard/HomePageTeamCard";


// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;


const TeamCarousel = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [fetchedTeamMembersFromDb,setFetchedTeamMembersFromDb] = useState([]);
    const getMembersData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/team-data`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log(res.data.data)
            setFetchedTeamMembersFromDb(res.data.data);
            // console.log(fetchedTeamMembersFromDb)
            setIsLoading(false)
        }
    }

    useEffect(() =>
    {
        getMembersData()
    },[])
    return (
        <div className={classes.TeamMembersContainer}>
            <div className={classes.leadership}><p>Our Leadership</p></div>
            <div className={classes.TeamMemberCards}>
                {
                    fetchedTeamMembersFromDb.map((member)=>{
                        return(
                            <div>
                                <HomePageTeamCard position={member.position} name={member.name} imgUrl={member.imgurl} vision={member.vision} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}
export default TeamCarousel;
