import React from 'react';
import "./Loading.css"

function Loading(props) {
    return (
        <div>
            <div className="backdrop">
                <div className="loader">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                </div>
            </div>
        </div>
    );
}

export default Loading;
