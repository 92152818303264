import classes from '../../TableStyles.module.css';
import deleteBtn from '../../../assets/close.svg';
import React, {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../../../Components/UI/Loading/Loading";


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;


function AwardsData({role}) {
    const [fetchedAwards,setFetchedAwards] = useState([]);
    const [loading,setLoading] = useState(null);
    const getAwardsData = async () => {
        setLoading(true)
        const res = await axios.get(`${url}/awards`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            // console.log(res.data.data);
            setFetchedAwards(res.data.data);
            setLoading(false);
        }
    }

    useEffect(()=>{
        getAwardsData()
    },[])


    async function onDeleteClickHandler(id){
        const delResult = await axios.delete(`${url}/awards/${id}`);
        if(delResult.data.status === 201){
            // console.log('data deleted');
            await getAwardsData();
        }
    }


    return (
        <div className={classes.container}>
            {loading && <Loading/>}
            {!loading && (
                <table>
                    <thead>
                    <tr>
                        <th>Award Name</th>
                        <th>Award For</th>
                        <th>Presented By</th>
                        <th>Year</th>
                        {role==='main'&&<th>Delete</th>}
                    </tr>
                    {
                        fetchedAwards.map((row)=>{
                            return(
                                <tr key={row.id}>
                                    <td>{row.awardname}</td>
                                    <td>{row.awardfor}</td>
                                    <td>{row.presentedby}</td>
                                    <td>{row.year}</td>
                                    {role==='main'&&<td style={{textAlign: 'center'}}><img src={deleteBtn} alt={'Delete'}
                                                                            height={'30px'} width={'30px'}
                                                                            onClick={() => onDeleteClickHandler(row.id)}/>
                                    </td>}
                                </tr>
                            )
                        })
                    }
                    </thead>
                </table>
            )}
        </div>
    );
}

export default AwardsData;
