import React, {useEffect, useState} from 'react';
import axios from "axios";
import Loading from "../UI/Loading/Loading";
import ImagesDisplay from "../UI/ImageDisplay/ImagesDisplay";


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function ClientsMain(props) {

    const [isLoading,setIsLoading] = useState(false);



    const [fetchedClientsImagesFromDb,setFetchedClientsImagesFromDb] = useState([])
    const getClientData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/our-clients`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            console.log('data get');
            setFetchedClientsImagesFromDb(res.data.data)
            setIsLoading(false)
            console.log(fetchedClientsImagesFromDb)
        }
    }

    useEffect(() => {getClientData()},[])

    return (
        <div>
            {isLoading ? <Loading/> : <ImagesDisplay data={fetchedClientsImagesFromDb} path={'clientsImg'}/> }
        </div>
    );
}

export default ClientsMain;
