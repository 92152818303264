import React, {useEffect, useState} from 'react';
import axios from "axios";
import PdfCard from "../../UI/PdfCard/PdfCard";
import classes from './NLComponent.module.css'
import Button from '@mui/material/Button';
import {Link} from "react-router-dom";
import Loading from "../../UI/Loading/Loading";
import {motion,useAnimation} from "framer-motion";
import {useInView} from "react-intersection-observer";


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function NLComponent() {
    const [ref,inView] = useInView({
        threshold:0.5  //80% of parent div must be there in view for animation to played
    });
    // const animationName = useAnimation();
    const nlAnimation = useAnimation();


    const [fetchedData,setFetchedData] = useState([])
    const [isLoading,setIsLoading] = useState(null);
    const getNLdata = async () => {
        setIsLoading(true);
        const res = await axios.get(`${url}/newsletters/top2`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            setFetchedData(res.data.data);
            setIsLoading(false);
        }
    }


    useEffect(()=>{
        // if(inView){
        //     animationName.start({
        //         x:0,
        //         transition:{
        //             type:'tween',
        //             // ease:"easeInOut",
        //             duration:1,
        //             // delay:3,
        //             // bounce:0.5
        //         }
        //     })
        // }
        // else{
        //     animationName.start({x:'-100vw'})
        // }
        if(inView){
            nlAnimation.start('visible');
        }
    },[nlAnimation,inView])


    useEffect(()=>{
        getNLdata();
    },[])

    console.log(fetchedData)
    return (
        <div ref={ref}>
            <motion.div
                // animate={animationName}
                animate={nlAnimation}
                initial="hidden"
                transition={{ type:'tween',
                    duration:1,
                }}
                variants={{
                    visible: { x:0 },
                    hidden: { x:'-100vw' }
                }}
                className={classes.nlContainer} >
                {isLoading && <Loading/>}
                {!isLoading &&
                <>
                    <div className={classes.cardContainer}>
                        <div className={classes.heading}>
                            <p>Newsletters</p>
                        </div>
                        <div className={classes.cards}>
                        {
                            fetchedData.map((pdf,id) => {
                                return(
                                    <div>
                                        <PdfCard testLogo={pdf.logourl} cname={pdf.clientname} heading={pdf.heading} brief={pdf.brief} pdfURL={pdf.pdfurl} staticUrlLogo={'clientsImg'} staticUrlPDF={'newsletterData'} />
                                    </div>
                                    )
                            })
                        }
                        </div>
                    </div>
                    <div className={classes.viewMoreContainer}>
                            <Button variant="outlined">
                                    <Link to={'/newsletter-updates'} target={"_blank"} style={{color:'black',textDecoration:'none', fontStyle:'italic'}} className={classes.linkClass}>View More</Link>
                            </Button>
                    </div>
                </>
                }
            </motion.div>
        </div>
    );
}

export default NLComponent;
