import React, {useEffect, useState} from 'react';
import Loading from "../../UI/Loading/Loading";
import axios from "axios";
import Gallery from "../Gallery";
import ImagesDisplay from "../../UI/ImageDisplay/ImagesDisplay";



// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function GalleryMain() {

    const [isLoading,setIsLoading] = useState(false);



    const [fetchedImagesFromDb,setFetchedImagesFromDb] = useState([])
    const getUserData = async () => {
        setIsLoading(true)
        const res = await axios.get(`${url}/images`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            console.log('data get');
            setFetchedImagesFromDb(res.data.data)
            setIsLoading(false)
        }
    }

    useEffect(()=>{
        getUserData()
    },[])


    return (
        <div>
            {isLoading ? <Loading/> : <ImagesDisplay data={fetchedImagesFromDb} path={'galleryImg'}/> }
        </div>
    );
}

export default GalleryMain;
