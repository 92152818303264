export const services = [
    {
        id:0,
        serviceName:'Representation',
        imageName:'sAndP',
        listOfServices:['Brand and product development','Positioning and communication','Crisis reporting and management']
    }
    ,{
        id:1,
        serviceName:'Sales Solutions',
        imageName:'research',
        listOfServices:['Market Perception and analysis','Consumer research']
    },{
        id:2,
        serviceName:'Strategy, Planning & Research',
        imageName:'mAndP',
        listOfServices:['Destination Representation & marketing','Event management','Product launch','Strategic alliances']
    },{
        id:3,
        serviceName:'Marketing & Promotion Options',
        imageName:'mAndPR',
        listOfServices:['Creative publicity campaigns','Media events and familiarization tours','Travel media exposure','Press conferences & One-on-One Interactions','Press Release & Feature Articles']
    },{
        id:4,
        serviceName:'Media Communications',
        imageName:'advertising',
        listOfServices:['Media Planning','Media Buying','Campaign Roll-out']
    },{
        id:5,
        serviceName:'Digital & Social Media Activations ',
        imageName:'onlineMarketing',
        listOfServices:['Online media campaigns','Blogs','Social Networking & applications','Online Monitoring']
    },{
        id:6,
        serviceName:'Content Development',
        imageName:'dAndP',
        listOfServices:['Collateral','Newsletters','Direct Mailers']
    },{
        id:7,
        serviceName:'Advertising & Media Buying ',
        imageName:'networking',
        listOfServices:['Trade Servicing','Enhancement of distribution channels','Media Alliances','Bollywood Access']
    },{
        id:8,
        serviceName:'Design & Production',
        imageName:'officeManagement',
        listOfServices:['Operations Management','In-house communication facilities']
    },{
        id:9,
        serviceName:'Trade Networking & Engagement',
        imageName:'adHoc',
        listOfServices:['Need based projects','Specialized assignments']
    },{
        id:10,
        serviceName:'Community Development',
        imageName:'adHoc',
        listOfServices:['Need based projects','Specialized assignments']
    },{
        id:11,
        serviceName:'Celebrity Connect',
        imageName:'adHoc',
        listOfServices:['Need based projects','Specialized assignments']
    },{
        id:12,
        serviceName:'Indian Cinema, TV & OTT Integrations',
        imageName:'adHoc',
        listOfServices:['Need based projects','Specialized assignments']
    },{
        id:13,
        serviceName:'Ad-Hoc Assignments',
        imageName:'adHoc',
        listOfServices:['Need based projects','Specialized assignments']
    },{
        id:14,
        serviceName:'Crisis Management',
        imageName:'adHoc',
        listOfServices:['Need based projects','Specialized assignments']
    },
];
