import React, {useEffect, useRef, useState} from "react";
import tracLogo from "../assets/TRACLOGO.jpg";
import Admin from "./Admin";
import axios from 'axios';
import Loading from "../Components/UI/Loading/Loading";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
export default function Verification(){


    //
    const [isVerified,setIsVerified] = useState(false);
    const [loading,setLoading] = useState(false);
    const [wrongPass, setWrongPass] = useState(false)
    // useEffect(()=>setIsVerified(false),[]);


    const formMain = {
        height:'30vh',
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }

    const formContainer={
        height: '50vh',
        width: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const input = {
        padding:'1%',
        margin:'0 2%',
        borderRadius:'2rem',
        outline:'0',
        border:'0px'
    }


    const [email,setEmail] = useState('')
    const [pass,setPass] = useState('')
    const [dropdownValue,setDropdownValue] = useState("");

    const [fetchedEmailFromDb,setFetchedEmailFromDb] = useState([])
    const submitHandler = async e => {
        e.preventDefault();
        setLoading(true)
        // console.log(dropdownValue+"<=>"+email+"<=>"+pass);

        try {
            const body = {email}
            axios.post(`${url}/admin-table-values/`,body).then(res=> {
                // console.log(res)
                setIsVerified(pass === res.data.pass && res.data.auth === dropdownValue);
                setWrongPass(pass !== res.data.pass || res.data.auth !== dropdownValue);
            })
            setLoading(false)
        } catch (error) {
            console.log(error.message)
        }
    }

      return(
          <>
              <img src={tracLogo} />
              {loading && !isVerified && <Loading />}
              {!loading && wrongPass && !isVerified && ( <div style={{height:'80vh',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}><p style={{textAlign:'center',fontFamily:"'Josefin Sans', sans-serif"}}>Please refresh and attempt again</p> </div>)}
              {
                  !isVerified && !loading && !wrongPass &&
                      (
                          <>
                              <div style={{height:'100px',width:'100%',marginTop:'50px'}}>
                                  <Box>
                                      <FormControl fullWidth style={{marginLeft:'40%'}}>
                                          <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                          <Select
                                              color="primary"
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              style={{width:'20%',color:'black'}}
                                              value={dropdownValue}
                                              label="Option"
                                              // onChange={e=>setDropdownValue(e.target.value)}
                                              onChange={(e) => setDropdownValue(e.target.value)}
                                          >
                                              <MenuItem value={"main"}>Admin Login</MenuItem>
                                              <MenuItem value={"uploader"}>Uploader Login</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </Box>
                              </div>
                              <form onSubmit={submitHandler} style={formMain}>
                                  <div style={formContainer}>
                                      <input type={'email'} name="email" id="email" placeholder={'Enter the email'} value={email} onChange={e => setEmail(e.target.value)} style={input}/>
                                      <input type={'password'} name="pass" id="pass" placeholder={'Enter the password'} value={pass} onChange={e => setPass(e.target.value)} style={input}/>
                                      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
                                          <Button variant="outlined" style={{width:'100px'}} type={'submit'}> Submit</Button>
                                      </div>
                                  </div>
                              </form>
                          </>
                      )
              }

              {
                !loading && isVerified && !wrongPass &&  (<Admin role={dropdownValue}/>)
              }
          </>
    )
}
