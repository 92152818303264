import React, {useEffect, useState} from 'react';
import axios from "axios";
import ImageWithTextSlider from "../../UI/ImageWithTextSlider/ImageWithTextSlider";
import Loading from "../../UI/Loading/Loading";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function ImageWithTextMain(props) {

    const [isLoading,setIsLoading] = useState(false);
    const [fetchedImageWithTextFromDb,setFetchedImageWithTextFromDb] = useState([])
    const getAwardImgTextData = async () => {
        setIsLoading(true);
        const res = await axios.get(`${url}/award-img-text`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            setFetchedImageWithTextFromDb(res.data.data.map(image => ({
                text:image.text,
                byLine:image.byline,
                image:image.imageurl
            })))
        }
        setIsLoading(false);
        // console.log("Carousel Images => " +fetchedCarouselImagesFromDb)
    }

    useEffect(() =>
    {
        getAwardImgTextData()
    },[])

    return ( isLoading?<Loading/>:<ImageWithTextSlider slides={fetchedImageWithTextFromDb} />);
}

export default ImageWithTextMain;
