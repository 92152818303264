import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./RootRouting";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import Home from "./Components/Home/Home";
import Verification from "./AdminPanel/Verification";
import {useState} from "react";
import GalleryMain from "./Components/Gallery/GalleryMainHandler/GalleryMain";
import ContactUs from "./Components/ContactUs/ContactUs";
import NewsletterPage from "./Components/NewsLetters/NewsletterPage";
import PressReleasePage from "./Components/PressReleases/PressReleasePage";
import AwardsAchievementsPage from "./Components/AwardsAchievements/AwardsAchievementsPage";
import ServicesPage from "./Components/Services/ServicesPage";
import PDFViewer from "./Components/UI/PDFViewer/PDFViewer";
import ClientsMain from "./Components/Clients/ClientsMain";
import TeamMembers from "./Components/TeamMembers/TeamMembers";
import {Helmet} from "react-helmet";



function App() {


    const routes = createBrowserRouter([
        {
            path:'/',
            element: <Root/>,
            errorElement: <ErrorPage />,
            children: [
                {index: true, element: <Home />},
                {path: '/gallery', element:<GalleryMain/>},
                {path: '/contact-us', element:<ContactUs/>},
                {path: '/newsletter-updates', element:<NewsletterPage/>},
                {path: '/press-release-updates', element:<PressReleasePage/>},
                {path: '/awards-achievements', element:<AwardsAchievementsPage/>},
                // {path: '/clients', element:<ClientsMain/>},
                {path: '/services', element:<ServicesPage/>},
                {path: '/our-team', element:<TeamMembers />},
                {path: '/view-release/:staticPath/:pdfurl', element:<PDFViewer/>},
                {path: '/admin', element:<Verification/>},
            ]
        },{
            path:'/TRACADMINPANEL',
            element:<Verification/>,
            errorElement:<ErrorPage />,
        }
        // ,{
        //     path:'/verified',
        //     element:<Admin onRefreshBtnClicked={isClicked}/>,
        // }
    ])
  return (
        <>
            <Helmet>
                <title>TracRep</title>
                <meta name="description" content="Hotels"  />
                <meta name="description" content="Flights"  />
                <meta name="description" content="Airfare"  />
                <meta name="description" content="Vacation"  />
                <meta name="description" content="Trip"  />
                <meta name="description" content="Cheap Flights"  />
                <meta name="description" content="Travel Agency"  />
                <meta name="description" content="Tour Operator"  />
                <meta name="description" content="Airlines"  />
                <meta name="description" content="Destination"  />
                <meta name="description" content="Travel News"  />
                <meta name="description" content="Budget Travel"  />
                <meta name="description" content="Travel Sites"  />
                <meta name="description" content="Vacation Packages"  />
                <meta name="description" content="Luxury Travel"  />
                <meta name="description" content="Experimental Travel"  />
                <meta name="description" content="Honeymoon Travel"  />
                <meta name="description" content="Adventure Travel"  />
                <meta name="description" content="Best tour companies"  />
                <meta name="description" content="Sustainable travel"  />
                <meta name="description" content="Online Booking"  />
                <meta name="description" content="European Travel"  />
                <meta name="description" content="Beaches"  />
                <meta name="description" content="Mountains"  />
                <meta name="description" content="Caribbean"  />
                <meta name="description" content="Resorts"  />
                <meta name="description" content="Islands"  />
                <meta name="description" content="Crises"  />
                <meta name="description" content="Wildlife tourism"  />
                <meta name="description" content="Safari"  />
                <meta name="description" content="Apartments"  />
                <meta name="description" content="Services apartments"  />
                <meta name="description" content="Accomodation"  />
                <meta name="description" content="Travel consultants"  />
                <meta name="description" content="Tourism Representations"  />
                <meta name="description" content="Corporate tavel agent"  />
                <meta name="description" content="Travel insurance"  />
                <meta name="description" content="Best travel deals"  />
                <meta name="description" content="Travel tips"  />
                <meta name="description" content="Summer travel"  />
                <meta name="description" content="Winter travel"  />
                <meta name="description" content="Best deal travel"  />
                <meta name="description" content="Travel"  />
                <meta name="description" content="Hospitality"  />
                <meta name="description" content="Tourism"  />
                <meta name="description" content="West Indies"  />
                <meta name="description" content="Africa"  />
                <meta name="description" content="Tours"  />
                <meta name="description" content="Visa on arrival"  />
            </Helmet>
            <RouterProvider router={routes} />
        </>
  );
}

export default App;

