import React from 'react';
import Marquee from "react-fast-marquee";
import classes from './MarQ.module.css'

// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;
function MarQ({ images, staticPath, title, speed, height, width,pause}) {

    const imageWrapper = {
        height:` ${height}px`,
        width: `${width}px`,
        margin: '0 30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const rowOne = images.filter(oddImages => oddImages.id%2===0)
    const rowTwo = images.filter(oddImages => oddImages.id%2!==0)

    return (
        <div className={classes["App"]}>
            <div className={classes["title"]}>
                <h1>{title}</h1>
            </div>

                <Marquee direction="right"
                         speed={speed}
                         // gradient={true}
                         pauseOnHover={pause}>
                    {images.map(({ id, image }) => (
                        <div key={id} style={imageWrapper}>
                            <img className={classes.marqImg} src={`${url}/${staticPath}/${image}`} alt={id} />
                        </div>
                    ))}
                </Marquee>
            {/*<Marquee direction="left" speed={100}>*/}
            {/*        {rowTwo.map(({ id, image }) => (*/}
            {/*            <div key={id} className={classes["image_wrapper"]}>*/}
            {/*                <img src={`${url}/${staticPath}/${image}`} alt={id} />*/}
            {/*            </div>*/}
            {/*        ))}*/}
            {/*    </Marquee>*/}

        </div>
    );
}

export default MarQ;
