import React from 'react';
import tracLogo from "../../assets/TRACLOGO.jpg";
import classes from "./OptNav.module.css";
import NavRes from "./NavRes/NavRes";
import {motion} from "framer-motion";


function OptNav() {
    return (
        <div className={classes.optNavContainer}>
            <div className={classes.rowOneContainer}>
                <div className={classes.row1}>
                    <div className={classes["trac-reps"]}>
                        <motion.span className={classes.trac}
                              initial={{ x: -1000 }}
                              animate={{ x: 0 }}
                              transition={{ delay: 1.5, duration: 1.5 }}
                        >TRAC</motion.span>
                        <motion.span className={classes.rep}
                                     initial={{ x: -1000 }}
                                     animate={{ x: 0 }}
                                     transition={{ delay: 2.5, duration: 1.5 }}
                        >WE MAKE CHALLENGES LOOK SIMPLE !<span></span></motion.span>
                    </div>
                    <motion.div className={classes.tracLogo}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 1.5, duration: 1.5 }}
                    >
                        <img src={tracLogo} height={"70px"} width={"150px"} alt={"X"} />
                    </motion.div>
                </div>
            </div>

            <div className={classes.row2}>
                <div className={classes.optNav}>
                    <NavRes />
                </div>
            </div>
        </div>
    );
}

export default OptNav;
