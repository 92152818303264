import classes from './PressReleasePage.module.css';
import axios from "axios";
import {useEffect, useState} from "react";
import Loading from "../UI/Loading/Loading";
import PdfCard from "../UI/PdfCard/PdfCard";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function PressReleasePage(props) {
    const [fetchedPRData,setFetchedPRData] = useState([]);
    const [loading,setLoading] = useState(null);

    const gePRData = async () => {
        setLoading(true);
        const res = await axios.get(`${url}/press-releases`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            // console.log('data get');
            // console.log(res.data.data)
            setFetchedPRData(res.data.data)
            setLoading(false)
        }
    }

    useEffect(()=>{
        gePRData()
    },[])

    return (
        <div className={classes.pdfContainer}>
            <div className={classes.prHeadingContainer}>
                <p>Press-Release Updates</p>
            </div>
            {loading && <Loading />}
            {!loading && (
                <>
                    {
                        fetchedPRData.length === 0
                            ?
                        <p className={classes.noData}>No New Data To Publish</p>
                            :
                        <div
                            className={classes.releaseContainer}
                        >

                             {
                                 fetchedPRData.map((pdf,id) => {
                                        return(
                                            <div className={classes.card}>
                                                <PdfCard testLogo={pdf.logourl} heading={pdf.heading} brief={pdf.brief} pdfURL={pdf.pdfurl} cname={pdf.clientname} staticUrlLogo={'clientsImg'} staticUrlPDF={'prData'}/>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    }
                </>
            )}
        </div>
    );
}

export default PressReleasePage;
