import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './ImageWithText.module.css';
import leftArrow from "../../../assets/white-arrow-left.png";
import rightArrow from "../../../assets/white-arrow-right.png";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function ImageWithTextSlider({slides}) {

    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const timerRef = useRef();

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    const goToNext = useCallback(() => {
        const isLastSlide = current === length - 1;
        const newIndex = isLastSlide ? 0 : current + 1;
        setCurrent(newIndex);
    }, [current, slides]);


    useEffect(()=> {
        console.log(slides[current]);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            goToNext();
        }, 9000);
        return () => clearTimeout(timerRef.current);
    },[goToNext])




    return (
        <section className={styles.slider}>
            <div className={styles.awardCitation}>
                <p>{slides[current]?.text} </p>
                <p>{slides[current]?.byLine}</p>
            </div>
            <div>
                {slides.map((slide, index) => {
                    return (
                        <div
                            className={index === current ? styles['slide active'] : styles.slide}
                            key={index}
                        >
                            {index === current && (
                                <img src={`${url}/awardImgText/${slide.image}`} alt='travel image' className={styles.image} />
                            )}
                        </div>
                    );
                })}
            </div>
        </section>
    );
}

export default ImageWithTextSlider;
