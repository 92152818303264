import React, {useState} from 'react';
import ReactDOM from "react-dom";
import classes from './Modal.module.css'
import {motion} from 'framer-motion'
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
const Backdrop = (props) => {
    return <motion.div className={classes.backdrop} onClick={props.onConfirm} initial={{opacity:0}} animate={{opacity:1}} transition={{delay:1.5, type:'spring'}}/>;
};

const ModalOverlay = (props) => {

    let isQuiz = props.messageType?.toLowerCase() === 'quiz';

    return (
        <motion.div className={classes.modal}
            initial={{y:'-100vh'}}
            animate={{y:0}}
            transition={{delay:2, type:'tween'}}
        >
            <header className={classes.header}>
                <h2>{props.title}</h2>
                <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={props.onConfirm} style={{cursor:'pointer'}} />
            </header>
            <div className={classes.content}>
                {/*<p>{props.logo}</p>*/}
                { props.logo.length!==0 && <img src={`${url}/clientsImg/${props.logo}`} alt={'X'} loading={"lazy"} height={'100px'} width={'130px'} style={{objectFit:'cover',objectPosition:'center'}}/> }
                {
                    isQuiz && (
                        <Link className={classes.linkClass} to={`${props.message}`}>
                            Click Here To Play Quiz
                        </Link>
                    )
                }
                {
                    !isQuiz && (
                        <p>{props.message}</p>
                    )
                }
            </div>
            <div className={classes.TandC}>
                <p>Terms & Conditions Apply. For More information on latest offer contact TRAC Representations.</p>
            </div>
        </motion.div>
    );
};

function Modal(props){
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <Backdrop onConfirm={props.onConfirm} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay
                    title={props.title}
                    message={props.message}
                    onConfirm={props.onConfirm}
                    logo={props.logourl}
                    messageType={props.messageType}
                />,
                document.getElementById('overlay-root')
            )}
        </React.Fragment>
    );
}

export default Modal;
