import React from 'react';
import classes from './ServiceTile.module.css'
import {services} from "../../Services/ServicesData";


function ServiceTile() {
    return (
        <div>
            <div className={classes["ag-offer-block"]}>
                <div className={classes["ag-format-container"]}>
                    <ul className={classes["ag-offer_list"]}>

                        {
                            services.map( (service)=>{
                                return(
                                    <li className={classes["ag-offer_item"]} key={service.id}>
                                        <div className={classes["ag-offer_visible-item"]}>
                                            <div className={classes["ag-offer_img-box"]}>
                                                <img
                                                    src="https://raw.githack.com/SochavaAG/example-mycode/master/pens/1_images/icon-64-1.svg"
                                                    className={classes["ag-offer_img"]} alt=""/>
                                            </div>
                                            <div className={classes["ag-offer_title"]}>
                                                {service.serviceName}
                                            </div>
                                        </div>
                                        <div className={classes["ag-offer_hidden-item"]}>
                                            <p className={classes["ag-offer_text"]}>
                                                <a href="/contact-us" className={classes["ag-offer_btn"]}>
                                                    Contact Us
                                                </a>
                                            </p>
                                        </div>
                                    </li>
                                )
                            } )
                        }

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default ServiceTile;
