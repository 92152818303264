import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Slider.css'
import rightArrow from '../../../assets/icons8-double-right-50.png'
import leftArrow from '../../../assets/icons8-double-left-50.png'

// let url = "http://localhost:8080";
let url = `${process.env.REACT_APP_NODE_PORT}`;
function Slider({slides}) {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const timerRef = useRef();

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    const goToNext = useCallback(() => {
        const isLastSlide = current === length - 1;
        const newIndex = isLastSlide ? 0 : current + 1;
        setCurrent(newIndex);
    }, [current, slides]);


    useEffect(()=> {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            goToNext();
        }, 9000);
        return () => clearTimeout(timerRef.current);
    },[goToNext])



    return (
        <section className='slider'>
            <img src={leftArrow} className={'left-arrow'} onClick={prevSlide}/>
            <img src={rightArrow} className={'right-arrow'} onClick={nextSlide}/>
            {slides.map((slide, index) => {
                return (
                    <div
                        className={index === current ? 'slide active' : 'slide'}
                        key={index}
                    >
                        {index === current && (
                            <img src={`${url}/mainCarouselImg/${slide.image}`} alt='travel image' className='image' />
                        )}
                    </div>
                );
            })}
        </section>


    );
}

export default Slider;
