import React from 'react';
import classes from './TeamMemberCard.module.css';

// let url = "http://localhost:8080"
let url = `${process.env.REACT_APP_NODE_PORT}`;

function TeamMemberCard({id,img,name,position,description}) {
    return (
        <div className={classes.memberContainer}>
            <div className={id %2 === 0 ? classes.teamBoxEven : classes.teamBoxOdd}>
                <div className={classes.memberPhoto}>
                    <img className={classes.photo}
                         src={`${url}/teamImg/${img}`}
                         alt={'X'}
                         loading={'lazy'}
                         // height={'270px'}
                         // width={'250px'}
                    />
                </div>
                <div className={classes.memberText}>
                    <div className={classes.memberName}>
                        <p>{name}</p>
                    </div>
                    <div className={classes.memberDesignation}>
                        <hr/>
                            <p>{position}</p>
                        <hr/>
                    </div>
                    <div className={classes.memberDesc}>
                        <p>{description}</p>
                    </div>

                </div>
            </div>
            <hr/>
        </div>
    );
}

export default TeamMemberCard;
