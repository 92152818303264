import classes from './AwardsAchievementsPage.module.css';

import {useEffect, useState} from "react";
import axios from "axios";
import Loading from "../UI/Loading/Loading";
import AwardImageMain from "./AwardScroll/AwardImageMain";
import ImageWithTextMain from "./ImageWithTextMain/ImageWithTextMain";
// import 'dotenv/config'


// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function AwardsAchievementsPage() {

    const [fetchedAwards,setFetchedAwards] = useState([]);
    const [loading,setLoading] = useState(null);
    const getAwardsData = async () => {
        setLoading(true)
        const res = await axios.get(`${url}/awards`,{
            headers:{
                'Content-Type':'application/json'
            }
        });
        if(res.data.status === 201){
            console.log('data get');
            console.log(res.data.data)
            setFetchedAwards(res.data.data)
            setLoading(false)
        }
    }

    useEffect(()=>{
        getAwardsData()
    },[])

    return (
        <>
            <div className={classes.awardsHeading}>
                <p>Achievements</p>
            </div>
            <div>
                <ImageWithTextMain />
            </div>
            <div>
                <AwardImageMain/>
            </div>
            <div className={classes.awardsHeading}>
                <p>Awards</p>
            </div>
            <div className={classes.container}>
                {loading && <Loading/>}
                {!loading && (
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">Award Name</th>
                                <th scope="col">Award For</th>
                                <th scope="col">Presented By</th>
                                <th scope="col">Year</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            fetchedAwards.map((row)=>{
                                return(
                                    <tr key={row.id}>
                                        <td scope={'row'} data-label={'Award Name'}>{row.awardname}</td>
                                        <td data-label={'Award For'}>{row.awardfor}</td>
                                        <td data-label={'Presented By'}>{row.presentedby}</td>
                                        <td data-label={'Year'}>{row.year}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
}

export default AwardsAchievementsPage;
