import React, {useState} from 'react';
import './PDFViewer.css';
import {useParams} from "react-router-dom";
import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function PdfViewer() {
    const params = useParams()

    const doc = [
        {
            uri: `${url}/${params.staticPath}/${params.pdfurl}`,
            fileType: "pdf",
            fileName: params.staticPath === 'newsletterData' ? "Newsletter Update" : "Press Release Update"
        },
    ]

    const [innerWidth,setInnerWidth] = useState(window.innerWidth);

    return (
        // <div style={{height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
        //     <embed src={`${url}/${params.staticPath}/${params.pdfurl}`}
        //            width="50%" height="80%"
        //            type="application/pdf">
        //     </embed>
        // </div>

        <div style={{height:'1200px',width:'100%',display:'flex',justifyContent:'center',alignItems:'center', marginTop:'70px'}}>
            <DocViewer
                documents={doc}
                pluginRenderers={DocViewerRenderers}
                // style={{height:'930px',width:'50%'}}
                style={{width: innerWidth <= '600px' ? '100%' : '70%', height:'100%'}}
                theme={{
                    primary: "#5296d8",
                    secondary: "#ffffff",
                    tertiary: "#5296d899",
                    textPrimary: "#ffffff",
                    textSecondary: "#5296d8",
                    textTertiary: "#00000099",
                    disableThemeScrollbar: false,
                }}
                config={{
                    pdfZoom: {
                        defaultZoom: 1, // 1 as default,
                        zoomJump: 0.5, // 0.1 as default,
                    },
                }}
            />
        </div>
    );
}

export default PdfViewer;
