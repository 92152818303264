import React, {useEffect, useState} from 'react';
import classes from './PdfCard.module.css';
import viewMore from '../../../assets/white-arrow-right.png';
import axios from "axios";
import {Link} from "react-router-dom";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function PdfCard({testLogo,cname,heading,brief, pdfURL,staticUrlPDF, staticUrlLogo}) {

    return (
        <div className={classes.divContainer}>
            <div className={classes.logoContainer}>
                {testLogo === '' && <p style={{color:'black'}}>{cname}</p>}
                {testLogo.length !== 0 &&
                <img src={`${url}/${staticUrlLogo}/${testLogo}`} loading={'lazy'} alt={'x'}/>
                }
            </div>
            <div className={classes.headingContainer}>
                <p>{heading}</p>
            </div>
            <div className={classes.briefContainer}>
                <p>{brief}</p>
            </div>
            <div className={classes.viewMoreContainer}>
                <button>
                    <Link className={classes.linkClass} target={"_blank"} to={`/view-release/${staticUrlPDF}/${pdfURL}`}>
                        View More
                        {/*<img src={viewMore} height={'20px'} width={'20px'}/>*/}
                    </Link>
                </button>
                {/*<button onClick={()=>viewMoreHandler(pdfURL)}>View More <img src={viewMore} height={'30px'} width={'30px'}/></button>*/}
            </div>
        </div>
    );
}

export default PdfCard;
