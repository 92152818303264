import React from 'react';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import classes from './HomePageTeamCard.module.css'

// let url = 'http://localhost:8080';
let url = `${process.env.REACT_APP_NODE_PORT}`;

function HomePageTeamCard({imgUrl,name,position,vision}) {

    return (
        <div className={classes.TeamMemberContainer}>
            <div className={classes.TeamMemberCardContainer}>
                <div className={classes.TeamMemberCardPicContainer}>
                    <div className={classes.TeamMemberCardPic} style={{backgroundImage:`url(${url}/teamImg/${imgUrl})`}}></div>
                </div>
                <div className={classes.TeamMemberCardName}>
                    <p>{name}</p>
                </div>
                <div className={classes.TeamMemberPosition}>
                    <hr/>
                        <p>{position}</p>
                    <hr/>
                </div>
                <div className={classes.TeamMemberVision}>
                    <p>{vision}</p>
                </div>
                <div className={classes.TeamMemberViewMore}>
                    <Button variant="outlined">
                        <Link to={'/our-team'} target={"_blank"} style={{color:'black',textDecoration:'none'}} className={classes.linkClass}>View More</Link>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default HomePageTeamCard;
