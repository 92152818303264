import React, {useState} from 'react';
import tracLogo from '../../../assets/TRACLOGO.jpg'
import {NavLink} from "react-router-dom";
import classes from "./Navbar.module.css"


function Navbar() {
    return (
        <div className={classes.navContainer}>
            <div className={classes.navImgContainer}>
                <img src={tracLogo} alt={'X'} height={'100%'} width={'50%'}/>
            </div>
            <nav className={classes.navOptions}>
                        <NavLink
                            to={"/"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'                                }
                            )
                            }

                            end
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to={"/newsletter-updates"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'                                }
                            )
                            }

                            end
                        >
                            Newsletters
                        </NavLink>
                        <NavLink
                            to={"/press-release-updates"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'                                }
                            )
                            }

                            end
                        >
                            Press Releases
                        </NavLink>
                        <NavLink
                            to={"/awards-achievements"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'                                }
                            )
                            }

                            end
                        >
                            Awards & Achievements
                        </NavLink>
                        <NavLink
                            to={"/gallery"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'                                }
                            )
                            }

                            end
                        >
                            Gallery
                        </NavLink>
                        {/*<NavLink*/}
                        {/*    to={"/clients"}*/}

                        {/*    style={({isActive}) => (*/}
                        {/*        {*/}
                        {/*            color: isActive ? 'white' : 'white'                                }*/}
                        {/*    )*/}
                        {/*    }*/}

                        {/*    end*/}
                        {/*>*/}
                        {/*    Clients*/}
                        {/*</NavLink>*/}
                        <NavLink
                            to={"/services"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'                                }
                            )
                            }

                            end
                        >
                            Services
                        </NavLink>
                        <NavLink
                            to={"/contact-us"}

                            style={({isActive}) => (
                                {
                                    color: isActive ? 'black' : 'black'
                                }
                            )
                            }

                            end
                        >
                            Contact Us
                        </NavLink>

                    </nav >
        </div>
    );
}

export default Navbar;
