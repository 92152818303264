import React, {useEffect, useState} from 'react';

import "./Gallery.css"

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCircleChevronLeft,
    faCircleChevronRight,
    faCircleXmark
} from '@fortawesome/free-solid-svg-icons'

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function Gallery(props) {

    // console.log(props.data)
    // const reduxRefreshBtnHandler = useSelector(state=>state.refreshBtn.isGalleryRefreshClick);
    //
    // console.log(reduxRefreshBtnHandler)

    // const [fetchedImagesFromDb,setFetchedImagesFromDb] = useState([])
    // const getUserData = async () => {
    //     const res = await axios.get(`${url}/images`,{
    //         headers:{
    //             'Content-Type':'application/json'
    //         }
    //     });
    //     if(res.data.status === 201){
    //         console.log('data get');
    //         setFetchedImagesFromDb(res.data.data)
    //     }
    // }
    //
    // useEffect(()=>{
    //     getUserData()
    // },[reduxRefreshBtnHandler])

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber( props.data.length -1 )
            : setSlideNumber( slideNumber - 1 )
    }

    // Next Image
    const nextSlide = () => {
        slideNumber + 1 === props.data.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    return (
        <div>
            {openModal &&
                <div className='sliderWrap'>
                    <FontAwesomeIcon icon={faCircleXmark} className='btnClose' onClick={handleCloseModal}  />
                    <FontAwesomeIcon icon={faCircleChevronLeft} className='btnPrev' onClick={prevSlide} />
                    <FontAwesomeIcon icon={faCircleChevronRight} className='btnNext' onClick={nextSlide} />
                    <div className='fullScreenImage'>
                        <img src={`${url}/galleryImg/${props.data[slideNumber].imageurl}`} loading={'lazy'}/>
                    </div>
                </div>
            }

            {/*{*/}
            {/*    props.data.map((img,id) => {*/}
            {/*        return(*/}
            {/*            <div key={id}>*/}
            {/*                <img src={`${url}/galleryImg/${img.imageurl}`} loading={'lazy'}/>*/}
            {/*                /!*<li>{moment(img.date).format("DD-MM-YYYY")}</li>*!/*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}

            <div className='galleryWrap'>
                {
                    props.data && props.data.map((slide, index) => {
                        return(
                            <div
                                className='single'
                                key={index}
                                onClick={ () => handleOpenModal(index) }
                            >
                                <img src={`${url}/galleryImg/${slide.imageurl}`} loading={'lazy'}/>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    );
}

export default Gallery;
