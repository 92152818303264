import React, {useEffect} from 'react';
import './ClientCarousel.css'
import {useInView} from "react-intersection-observer";
import {useAnimation,motion} from "framer-motion";

let url = 'http://localhost:8080'

function ClientCarousel({ images, staticPath, title }) {

    const [ref,inView] = useInView({
        threshold:0.3  //80% of parent div must be there in view for animation to played
    });

    const headingAnimation = useAnimation();
    const clientScrollAnimation = useAnimation();

    useEffect(()=>{
        if(inView){
            headingAnimation.start({
                y:0,
                transition:{
                    type:'tween',
                    duration:0.5,
                    // delay:3,
                    // bounce:0.5
                }
            })
            clientScrollAnimation.start({
                x:0,
                transition:{
                    type:'tween',
                    duration:1,
                    // delay:3,
                    // bounce:0.5
                }
            })
        }
        else{
            headingAnimation.start({y:'-1000vh'})
            // clientScrollAnimation.start({x:'-1000vw'})
        }
    },[inView]);

    return (
        <div className={'CCContainer'} ref={ref}>
            <motion.div className={'CCHeading'} animate={headingAnimation}>
                <span style={{fontFamily: "'Cinzel Decorative', cursive"}}>{title} </span>
            </motion.div>
            <motion.div className="CCinner"  animate={clientScrollAnimation}>
                <div className="CCwrapper">
                    <section>
                        {images.map(({ id, image }) => (
                            <div key={id} className="client-image">
                                <img className={'CCimg'} src={`${url}/${staticPath}/${image}`} alt={id} />
                            </div>
                        ))}
                    </section>
                    <section>
                        {images.map(({ id, image }) => (
                            <div className="client-image" key={id}>
                                <img className={'CCimg'} src={`${url}/${staticPath}/${image}`} alt={id} />
                            </div>
                        ))}
                    </section>
                    <section>
                        {images.map(({ id, image }) => (
                            <div className="client-image" key={id}>
                                <img className={'CCimg'} src={`${url}/${staticPath}/${image}`} alt={id} />
                            </div>
                        ))}
                    </section>
                </div>
            </motion.div>
        </div>
    );
}

export default ClientCarousel;
