import {useEffect, useState} from "react";
import axios from 'axios';
import pdfIcon from '../assets/pdficon.png';
import imgIcon from '../assets/jpg.png';


import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import YTVideoData from "./Components/YTVIdeoData/YTVideoData";
import GalleryImageData from "./Components/GalleryImageData/GalleryImageData";
import AwardsData from "./Components/AwardsData/AwardsData";
import ClientsData from "./Components/ClientsData/ClientsData";
import NewsletterData from "./Components/NewsletterData/NewsletterData";
import PRData from "./Components/PressReleaseData/PRData";
import AwardWithTextDescData from "./Components/AwardWithTextDesc/AwardWithTextDescData";
import AwardImgData from "./Components/AwardImgData/AwardImgData";
import loading from "../Components/UI/Loading/Loading";
import Loading from "../Components/UI/Loading/Loading";
import HomePageMainImgData from "./Components/HomePageMainImageData/HomePageMainImgData";
import PopupData from "./Components/PopupData/PopupData";

// let url = 'http://localhost:8080'
let url = `${process.env.REACT_APP_NODE_PORT}`;
function Admin({role}) {

    const [imgFile,setImgFile] = useState('');
    const [isSuccess,setIsSuccess] = useState(false)


    const [dropdownValue,setDropdownValue] = useState("default");
    const [urlValue,setURLValue] = useState("");


    //newsletter state:
    const[cname,setCName] = useState('')
    const[heading,setHeading] = useState('')
    const[pdfFile,setPdfFile] = useState('')
    const[logoFile,setLogoFile] = useState('')
    const[brief,setBrief] = useState('')


    //award state
    const [aname,setAName] = useState('')
    const [afor,setAFor] = useState('')
    const [apresby,setAPresBy] = useState('')
    const [ayear,setAYear] = useState('')


    //yt video state
    const[videoUrl,setVideoUrl] = useState('')
    const[videoTitle,setVideoTitle] = useState('')

    //send-email form
    const[clientName,setClientName] = useState('');
    const[redirectURL,setRedirectURL] = useState('');
    const[emailTitle,setEmailTitle] = useState('');


    //send team data
    const[memberName,setMemberName] = useState('');
    const[position,setPosition] = useState('');
    const[vision,setVision] = useState('');
    const[desc,setDesc] = useState('');
    const[pic,setPic] = useState('');


    //award image with text
    const [awardPic,setAwardPic] = useState('');
    const [awardTextDesc,setAwardTextDesc] = useState('');
    const [byLine, setByLine] = useState('');


    //popup enabler
    const [sdate,setSdate] = useState('');
    const [edate,setEdate] = useState('');
    const [logourl,setLogourl] = useState('');
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [messageType,setMessageType] = useState('')

    const dropDownHandler = e => {
        setDropdownValue(e.target.value);
        if(e.target.value === 'nl'){
            setURLValue('newsletters');
        }else if(e.target.value === 'pr'){
            setURLValue('press-releases');
        }else if(e.target.value === 'client'){
            setURLValue('our-clients');
        }else if(e.target.value === 'gallery'){
            setURLValue('images');
        }else if(e.target.value==='award'){
            setURLValue('awards');
        }else if(e.target.value==='yt'){
            setURLValue('yt-data');
        }else if(e.target.value==='email'){
            setURLValue('send-email');
        }else if(e.target.value==='awardImg'){
            setURLValue('award-img');
        }else if(e.target.value==='team'){
            setURLValue('team-data');
        }else if(e.target.value==='maincarousel'){
            setURLValue('main-carousel');
        }else if(e.target.value==='awardimgwithtext'){
            setURLValue('award-img-text');
        }else if(e.target.value==='popup'){
            setURLValue('popup');
        }
    }


    function myFun(e){
        setImgFile(e.target.files[0])
    }

    async function newsLetterHandler(e){
        e.preventDefault()
        let formData = new FormData()
        formData.append("clientName",cname);
        formData.append("heading",heading);
        formData.append("pdf",pdfFile);
        formData.append("logo",logoFile);
        formData.append("brief",brief);

        // console.log(formData)

        const config = {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }

        // const res = await axios.post(`${url}/newsletters`,formData,config);
        const res = await axios.post(`${url}/${urlValue}`,formData,config);
        if(res.data.status === 201){
            // console.log(res)
            setImgFile('')
            alert('Image sent to DB!')
            setIsSuccess(true)
        }else{
            console.log('error')
        }
    }
    async function clickHandler(e){
        e.preventDefault()
        // console.log(imgFile)

        let formData = new FormData()
        formData.append("photo",imgFile);

        const config = {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }

        const res = await axios.post(`${url}/${urlValue}`,formData,config);
        if(res.data.status === 201){
            // console.log(res)
            setImgFile('')
            alert('Image sent to DB!')
            setIsSuccess(true)
        }else{
            console.log('error')
        }
    }
    const awardInputHandler = async e => {
        e.preventDefault()
        try{
            const body = {aname,afor,apresby,ayear}
            const response = await fetch(`${url}/${urlValue}`,{
                method:'POST',
                headers:{'content-type':'application/json'},
                body:JSON.stringify(body)

            })
            // window.location='/TRACADMINPANEL'
            alert('Query Submitted!')
            // console.log(response)
            setIsSuccess(true)
        }catch (error){
            console.log(error.message)
        }
    }
    const ytInputHandler = async e => {
        e.preventDefault();
        try{
            const body = {videoUrl,videoTitle}
            const response = await fetch(`${url}/${urlValue}`,{
                method:'POST',
                headers:{'content-type':'application/json'},
                body:JSON.stringify(body)

            })
            // window.location='/TRACADMINPANEL'
            alert('Query Submitted!')
            // console.log(response)
            setIsSuccess(true)
        }catch (error){
            console.log(error.message)
        }
    }

    const sendEmailHandler = async e =>{
        e.preventDefault();
        try{
            const body = {clientName,redirectURL,emailTitle}
            const response = await fetch(`${url}/${urlValue}`,{
                method:'POST',
                headers:{'content-type':'application/json'},
                body:JSON.stringify(body)

            })
            alert('Query Submitted!')
            setIsSuccess(true)

        }catch (error){
            console.log(error.message)
        }
    }


    const teamDataHandler = async e => {
        e.preventDefault();
        let formData = new FormData()
        formData.append("memberName",memberName);
        formData.append("position",position);
        formData.append("pic",pic);
        formData.append("desc",desc);
        formData.append("vision",vision);

        // console.log(formData)

        const config = {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }

        // const res = await axios.post(`${url}/newsletters`,formData,config);
        const res = await axios.post(`${url}/${urlValue}`,formData,config);
        if(res.data.status === 201){
            // console.log(res)
            setImgFile('')
            alert('Image sent to DB!')
            setIsSuccess(true)
        }else{
            console.log('error')
        }
    }

    const awardTextHandler = async e => {
        e.preventDefault();
        let formData = new FormData()
        formData.append("awardPic",awardPic);
        formData.append("textDesc",awardTextDesc);
        formData.append("byline",byLine);

        // console.log(formData);

        const config = {
            headers:{
                'Content-Type':'multipart/form-data'
            }
        }

        // const res = await axios.post(`${url}/newsletters`,formData,config);
        const res = await axios.post(`${url}/${urlValue}`,formData,config);
        if(res.data.status === 201){
            // console.log(res)
            setImgFile('')
            alert('Image sent to DB!')
            setIsSuccess(true)
        }else{
            console.log('error')
        }
    }

    const popupEnableHandler = async e => {
        e.preventDefault()
        try{
            const body = {sdate,edate,logourl,title,message,messageType}
            const response = await fetch(`${url}/${urlValue}`,{
                method:'POST',
                headers:{'content-type':'application/json'},
                body:JSON.stringify(body)

            })
            // window.location='/TRACADMINPANEL'
            alert('Query Submitted!')
            // console.log(response)
            setIsSuccess(true)
        }catch (error){
            console.log(error.message)
        }
    }

    return (
        <>
            <div>
                    <div style={{minHeight:'100px',width:'100%', backgroundColor:'white',paddingTop:'5%'}} >
                        <Box>
                            <FormControl fullWidth style={{marginLeft:'40%'}}>
                                <InputLabel id="demo-simple-select-label">Upload</InputLabel>
                                <Select
                                    color="primary"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{width:'20%',color:'black'}}
                                    // value={dropdownValue}
                                    label="Option"
                                    // onChange={e=>setDropdownValue(e.target.value)}
                                    onChange={dropDownHandler}
                                >
                                    <MenuItem value={"maincarousel"}>Home Page Big Image Data</MenuItem>
                                    <MenuItem value={"client"}>Clients Image</MenuItem>
                                    <MenuItem value={"yt"}>Youtube Video URL's</MenuItem>
                                    <MenuItem value={"nl"}>Newsletter Upload</MenuItem>
                                    <MenuItem value={"pr"}>Press-Release Upload</MenuItem>
                                    <MenuItem value={"awardimgwithtext"}>Award Image With Description</MenuItem>
                                    <MenuItem value={"awardImg"}>Small Award Image Without Description</MenuItem>
                                    <MenuItem value={"award"}>Awards</MenuItem>
                                    <MenuItem value={"gallery"}>Gallery Image</MenuItem>
                                    <MenuItem value={"team"}>Team Data Update</MenuItem>
                                    <MenuItem value={"email"}>Send Email On Click</MenuItem>
                                    <MenuItem value={"popup"}>Enable PopUp</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
            </div>
            <div style={divContainer}>
            {
                dropdownValue === 'gallery' && (
                    <>
                        <div style={formsContainerBox}>
                            <p style={{marginTop:'5%',marginBottom:'5%', color:'white'}}>Gallery Specific Images</p>
                            <div style={formStyle}>
                                <div style={iconBox}>
                                    <img src={imgIcon} alt={'x'} loading={'lazy'}/>
                                </div>
                                <form onSubmit={clickHandler}>
                                    <label htmlFor={'photo'}>Gallery Image: </label>
                                    <input type={'file'} name={'photo'} id={'photo'} onChange={myFun}/>
                                    <button type={'submit'}>Submit</button>
                                </form>
                            </div>
                        </div>
                        <p> Uploaded Images data in Database </p>
                        <GalleryImageData role={role}/>
                    </>
                )
            }
            {
                dropdownValue === 'client' && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Clients Image</p>
                        <div style={formStyle}>
                            <div style={iconBox}>
                                <img src={imgIcon} alt={'x'} loading={'lazy'}/>
                            </div>
                            <form onSubmit={clickHandler}>
                                <p> Approx Size: height: 300px, width:200px </p>
                                <label htmlFor={'photo'}>Client Image </label>
                                <input type={'file'} name={'photo'} id={'photo'} onChange={myFun}/>
                                <button type={'submit'}>Submit</button>
                            </form>
                        </div>
                        <p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>
                        <ClientsData role={role}/>
                    </>

                )
            }

            {
                (dropdownValue === 'nl' || dropdownValue === 'pr') && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>{dropdownValue==='nl'?'NewsLetter':'Press-Release'} - upload</p>
                        <div style={formStyle}>
                            <div style={iconBox}>
                                <img src={pdfIcon} alt={'x'} loading={'lazy'}/>
                            </div>
                            <form onSubmit={newsLetterHandler}>
                                <div>
                                    <label htmlFor={'clientName'}>Client Name: </label>
                                    <input type={'text'} name={'clientName'} id={'clientName'} value={cname}  onChange={(e)=>setCName(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'heading'}>Heading of Article: </label>
                                    <input type={'text'} name={'heading'} id={'heading'}  value={heading} onChange={(e)=>setHeading(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'pdf'}>Newsletter (PDF Only): </label>
                                    <input type={'file'} name={'pdf'} id={'pdf'} required={true} onChange={(e)=>setPdfFile(e.target.files[0])}/>
                                </div>
                                <div>
                                    <label htmlFor={'logo'}>Newsletter Logo: </label>
                                    {/*<input type={'file'} name={'logo'} id={'logo'} onChange={(e)=>setLogoFile(e.target.files[0])}/>*/}
                                    <input type={'text'} name={'logo'} id={'logo'} onChange={(e)=>setLogoFile(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'brief'}>Brief about article: </label>
                                    <textarea name={'brief'} id={'brief'}  rows="4" cols="80" required={true} value={brief} onChange={(e)=>setBrief(e.target.value)} />
                                </div>
                                <button type={'submit'}>Submit</button>
                            </form>
                        </div>
                        <p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>
                        {dropdownValue === 'nl' && <NewsletterData role={role}/> }
                        {dropdownValue === 'pr' && <PRData role={role}/> }
                    </>
                )
            }
            {
                dropdownValue === 'award' && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Award - upload</p>
                        <div style={formStyle}>
                            <form onSubmit={awardInputHandler}>
                                <div>
                                    <label htmlFor={'award'}>Award Name: </label>
                                    <input type={'text'} name={'award'} id={'award'} value={aname}  onChange={(e)=>setAName(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'awardFor'}>Award For: </label>
                                    <input type={'text'} name={'awardFor'} id={'awardFor'}  value={afor} onChange={(e)=>setAFor(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'presentedBy'}>Presented By: </label>
                                    <input type={'text'} name={'presentedBy'} id={'presentedBy'}  value={apresby} onChange={(e)=>setAPresBy(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'year'}>Year: </label>
                                    <input type={'text'} name={'year'} id={'year'} value={ayear} onChange={(e)=>setAYear(e.target.value)}/>
                                </div>
                                <button type={'submit'}>Upload</button>
                            </form>
                        </div>
                        <p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>
                        <AwardsData role={role}/>
                    </>
                )
            }
            {
                dropdownValue === 'yt' && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Youtube Link - upload</p>
                        <div style={formStyle}>
                            <form onSubmit={ytInputHandler}>
                                <div>
                                    <label htmlFor={'videoUrl'}>Video Url: </label>
                                    <input type={'text'} name={'videoUrl'} id={'videoUrl'} value={videoUrl}  onChange={(e)=>setVideoUrl(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'videoTitle'}>Video Title: </label>
                                    <input type={'text'} name={'videoTitle'} id={'videoTitle'}  value={videoTitle} onChange={(e)=>setVideoTitle(e.target.value)}/>
                                </div>
                                <button type={'submit'}>Upload</button>
                            </form>
                        </div>

                        <div>
                            <p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>
                            <YTVideoData role={role}/>
                        </div>
                    </>
                )
            }
            {
                dropdownValue === 'email' && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Send Email</p>
                        <div style={formStyle}>
                            <form onSubmit={sendEmailHandler}>
                                <div>
                                    <label htmlFor={'clientName'}>Client Name: </label>
                                    <input type={'text'} name={'clientName'} id={'clientName'} value={clientName}  onChange={(e)=>setClientName(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'emailTitle'}>Email Title: </label>
                                    <input type={'emailTitle'} name={'emailTitle'} id={'emailTitle'}  value={emailTitle} onChange={(e)=>setEmailTitle(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'redirectURL'}>Newsletter/Press-Release URL: </label>
                                    <input type={'redirectURL'} name={'redirectURL'} id={'redirectURL'}  value={redirectURL} onChange={(e)=>setRedirectURL(e.target.value)}/>
                                </div>
                                <button type={'submit'}>Burst Email</button>
                            </form>
                        </div>

                        {/*<div>*/}
                        {/*    <p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>*/}
                        {/*    <YTVideoData />*/}
                        {/*</div>*/}
                    </>
                )
            }
            {
                dropdownValue === 'awardImg' && (
                    <>
                        <>
                            <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Award Image</p>
                            <div style={formStyle}>
                                <div style={iconBox}>
                                    <img src={imgIcon} alt={'x'} loading={'lazy'}/>
                                </div>
                                <form onSubmit={clickHandler}>
                                    <p> Approx Size: height: 500px, width:400px </p>
                                    <label htmlFor={'photo'}>Award Image: </label>
                                    <input type={'file'} name={'photo'} id={'photo'} onChange={myFun}/>
                                    <button type={'submit'}>Submit</button>
                                </form>
                            </div>
                            <p>Uploaded Award Image Data (Click Delete Icon to Remove the Data from Display)</p>
                            <AwardImgData role={role}/>
                        </>
                    </>
                )
            }
            {
                (dropdownValue === 'team') && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Team Member - upload</p>
                        <div style={formStyle}>
                            <form onSubmit={teamDataHandler}>
                                <div>
                                    <label htmlFor={'memberName'}>Member Name: </label>
                                    <input type={'text'} name={'memberName'} id={'memberName'} value={memberName}  onChange={(e)=>setMemberName(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'position'}>Position of Member: </label>
                                    <input type={'text'} name={'position'} id={'position'}  value={position} onChange={(e)=>setPosition(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'desc'}>Brief Description: </label>
                                    <textarea name={'desc'} id={'desc'}  rows="4" cols="80" required={true} value={desc} onChange={(e)=>setDesc(e.target.value)} />
                                </div>
                                <div>
                                    <label htmlFor={'vision'}>Vision: </label>
                                    <textarea name={'vision'} id={'vision'}  rows="4" cols="80" required={true} value={vision} onChange={(e)=>setVision(e.target.value)} />
                                </div>
                                <div>
                                    <label htmlFor={'pic'}>Member Pic: </label>
                                    <input type={'file'} name={'pic'} id={'pic'} required={true} onChange={(e)=>setPic(e.target.files[0])}/>
                                </div>
                                <button type={'submit'}>Submit</button>
                            </form>
                        </div>
                        {/*<p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>*/}
                    </>
                )
            }
            {
                dropdownValue === 'maincarousel' && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Home Page Main Image</p>
                        <div style={formStyle}>
                            <div style={iconBox}>
                                <img src={imgIcon} alt={'x'} loading={'lazy'}/>
                            </div>
                            <form onSubmit={clickHandler}>
                                <label htmlFor={'photo'}>Home Page Main Image: </label>
                                <input type={'file'} name={'photo'} id={'photo'} onChange={myFun}/>
                                <button type={'submit'}>Submit</button>
                            </form>
                        </div>
                        <p>Uploaded Home Page Big Pictures (Click Delete Icon to Remove the Data from Display)</p>
                        <HomePageMainImgData role={role}/>
                    </>

                )
            }
            {
                dropdownValue === 'awardimgwithtext' && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Award Image with Description</p>
                        <div style={formStyle}>
                            <form onSubmit={awardTextHandler}>
                                <label htmlFor={'awardPic'}>Award Receiving Image: </label>
                                <input type={'file'} name={'awardPic'} id={'awardPic'} onChange={(e)=>setAwardPic(e.target.files[0])}/> <br/>
                                <label htmlFor={'textDesc'}>Text Desc: </label>
                                <input type={'text'} name={'textDesc'} id={'textDesc'} value={awardTextDesc} onChange={(e) => setAwardTextDesc(e.target.value)} /> <br/>
                                <label htmlFor={'byline'}>By Line: </label>
                                <input type={'text'} name={'byline'} id={'byline'} value={byLine} onChange={(e) => setByLine(e.target.value)} /> <br/>
                                <button type={'submit'}>Submit</button>
                            </form>
                        </div>
                        <p>Uploaded Data (Click Delete Icon to Remove the Data from Display)</p>
                        <AwardWithTextDescData role={role}/>
                    </>

                )
            }
            {
                dropdownValue === 'popup'
                && (
                    <>
                        <p style={{marginTop:'5%',marginBottom:'5%', color:'black'}}>Enable - Popup</p>
                        <div style={formStyle}>
                            <form onSubmit={popupEnableHandler}>
                                <div>
                                    <label htmlFor={'startdate'}>Start Date: </label>
                                    <input type={'datetime-local'} name={'startdate'} id={'startdate'} value={sdate}  onChange={(e)=>setSdate(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'enddate'}>End Date: </label>
                                    <input type={'datetime-local'} name={'enddate'} id={'enddate'}  value={edate} onChange={(e)=>setEdate(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'logourl'}>Logo Url: </label>
                                    <input type={'text'} name={'logourl'} id={'logourl'}  value={logourl} onChange={(e)=>setLogourl(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'title'}>Title: </label>
                                    <input type={'text'} name={'title'} id={'title'} value={title} onChange={(e)=>setTitle(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'message'}>Message: </label>
                                    <input type={'text'} name={'message'} id={'message'} value={message} onChange={(e)=>setMessage(e.target.value)}/>
                                </div>
                                <div>
                                    <label htmlFor={'message'}> Type Message (promotion/quiz): </label>
                                    <input type={'text'} name={'messagetype'} id={'messagetype'} value={messageType} onChange={(e)=>setMessageType(e.target.value)}/>
                                </div>
                                <button type={'submit'}>Enable</button>
                            </form>
                        </div>
                        <p>Please remove all previous popup data and then upload new (Click Delete Icon to Remove the Data from Display)</p>
                        <PopupData role={role}/>
                    </>
                )
            }
            </div>
        </>
    );
}

const divContainer = {
    minHeight:'70vh',
    width:'100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column'
}
const formsContainerBox = {
    width:'50%',
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    alignItems:'center',
    marginBottom:'3%'
}
const formStyle = {
    height:'fit-content',
    width:'50%',
    backgroundColor: 'white',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection: 'column',
    border:'4px dotted red',
    padding:'5%',
    marginBottom: '3%'
}
const iconBox = {
    height: '200px',
    width: '200px',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    cursor: 'pointer'
}
export default Admin;
